import React from 'react';
import { Button } from "@mui/material";

import jsPDF from 'jspdf';
import * as ReactDOM from 'react-dom';
import html2canvas from 'html2canvas';
import completed_workshop_certificate from '../../../images/completed_workshop_certificate.PNG';
import { makeStyles, Typography } from '@material-ui/core';
import { romanTest, capitalizeFoundParameter } from '../../../utils/library';


const styles = makeStyles(() => ({
    workshopCertificate: {
        position: "absolute",
    },
    certificateImageContainer: {
        width: "100%",
        height: "100%",
    },
    facultyNameContainer: {
        position: "absolute",
        top: "40%",
        width: "100%",
        alignItems: "center",
    },
    workshopNameContainer: {
        position: "absolute",
        top: "53%",
        width: "100%",
        alignItems: "center",
    },
    workshopDatesContainer: {
        position: "absolute",
        top: "70%",
        width: "100%",
        alignItems: "center",
    },
    awardDateContainer: {
        position: "absolute",
        top: "77%",
        left: "20%",
    },
    facultyNameText: {
        fontFamily: "IBM Plex Serif",
        fontWeight: "500",
        fontSize: "32px",
        fontStyle: "normal",
        color: "#DB3725",
        textAlign: "center",
    },
    workshopNameText: {
        fontFamily: "IBM Plex Serif",
        fontWeight: "500",
        fontSize: "40px",
        fontStyle: "normal",
        color: "#001823",
        textAlign: "center",
    },
    workshopDatesText: {
        fontFamily: "IBM Plex Serif",
        fontWeight: "500",
        fontSize: "18px",
        fontStyle: "normal",
        color: "#001823",
        textAlign: "center",
    },
    awardDateText: {
        fontFamily: "IBM Plex Serif",
        fontWeight: "700",
        fontSize: "20px",
        fontStyle: "normal",
        color: "#001823",
        textAlign: "center",
    },
}));

export function ViewCertificate(props) {

    const classes = styles();

    const handleDateFormat = (date) => {
        // Split the YYYY-MM-DD format
        const [year, month, day] = date.split('-').map(num => parseInt(num, 10));
        
        // Return in MM/DD/YYYY format
        return `${month}/${day}/${year}`;
    };

    const handleNameCapitalization = (name) => {
        name = name.toLowerCase();
        name = name.split(" ").map(word => word[0].toUpperCase() + word.slice(1)).join(" ");
        return name;
    };

    const generateImage = async () => {

        // Create an div element
        const newDivElement = document.createElement('div');
        newDivElement.id = 'workshopCertificate2';

        // Render the component to the temporary div element with an id that could be used for retrieving the element in htmtl2canvas step
        ReactDOM.render(
            <div id="workshopCertificate" className={classes.workshopCertificate}> 
                <div className={classes.certificateImageContainer}>
                    <img src={completed_workshop_certificate} alt="" />
                </div>
                <div className={classes.facultyNameContainer}>
                    <Typography className={classes.facultyNameText}>{handleNameCapitalization(props.facultyName)}</Typography>
                </div>
                <div className={classes.workshopNameContainer}>
                    <Typography className={classes.workshopNameText}>{capitalizeFoundParameter(romanTest(props.workshopName), ["ai"])}</Typography>
                </div> 
                <div className={classes.workshopDatesContainer}>
                    <Typography className={classes.workshopDatesText}>{handleDateFormat(props.startDate)}&nbsp;{<span>to</span>}&nbsp;{handleDateFormat(props.endDate)}</Typography>
                </div>
                <div className={classes.awardDateContainer}>
                    <Typography className={classes.awardDateText}>{handleDateFormat(props.endDate)}</Typography>
                </div>
            </div>
            , newDivElement); //temporary div element

        document.body.appendChild(newDivElement);

        //Convert the React component to an image using html2canvas
        html2canvas(document.getElementById('workshopCertificate')).then((canvas) => {

            // Create a new jsPDF instance
            const pdf = new jsPDF('l');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const widthRatio = pdfWidth / canvas.width;
            const heightRatio = pdfHeight / canvas.height;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;

            const marginX = (pdfWidth - canvasWidth) / 2;
            const marginY = (pdfHeight - canvasHeight) / 2;

            // Add the image to the PDF
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', marginX, marginY, canvasWidth, canvasHeight ); 

            pdf.setDisplayMode(100, null , "FullScreen");

            // Open the PDF in a new tab or window
            window.open(pdf.output('bloburl'), '_blank') ;
  
        });

        // Cleanup function: Remove the added element 
        document.body.removeChild(newDivElement);

        return null;
    };

    return (
        <div>
            <Button
                onClick={generateImage}
                sx={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    color: "#ffffff",
                    fontSize: "12px",
                    textDecoration: "none",
                    textTransform: "none",
                    borderBottom: "3px solid #ffffff",
                    borderRadius: "0px",
                    padding: "0px",
                }}
            >
                View/print certificate
            </Button>
        </div >

    );
};
